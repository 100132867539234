import nikeCoverVid from "../../../../src/video/nike/nike-cover.mp4";
import buildingExpandVid from "../../../../src/video/nike/building-expand.mp4";
import cameraPathVid from "../../../../src/video/nike/camera-path.mp4";
import galleryObjects from "../../../../src/video/nike/gallery-objects.mp4";
import shoeCloud from "../../../../src/video/nike/shoe-cloud.mp4";
import videoScroll from "../../../../src/video/nike/video-scroll.mp4";
import * as React from 'react';
export default {
  nikeCoverVid,
  buildingExpandVid,
  cameraPathVid,
  galleryObjects,
  shoeCloud,
  videoScroll,
  React
};