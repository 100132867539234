import gradientVid from "../../../../src/images/paint-system/gradient.mp4";
import squareVid from "../../../../src/images/paint-system/stroke-square.mp4";
import fingerPainting from "../../../../src/images/paint-system/fingerpainting.mp4";
import smearTestVid from "../../../../src/images/paint-system/smear-test.mp4";
import * as React from 'react';
export default {
  gradientVid,
  squareVid,
  fingerPainting,
  smearTestVid,
  React
};