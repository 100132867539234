import currentsVid from "../../../../src/video/currents-quiz-sm.mp4";
import currentsVidPoster from "../../../../src/images/currents/video-poster.jpg";
import currentsMapVid from "../../../../src/video/currents-map.mp4";
import currentsMapVidPoster from "../../../../src/images/currents/map-poster.jpg";
import * as React from 'react';
export default {
  currentsVid,
  currentsVidPoster,
  currentsMapVid,
  currentsMapVidPoster,
  React
};